import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage6() {
  const question = {
    title: 'Frage 6 von 35',
    headline: '',
    subline: 'Ich pflege vor allem gerne Patienten, ...',
  }

  const answers = [
    {
      name: 'Bewusstseinszustand',
      id: 'bewusstsein',
      value: '... die bei vollem Bewusstsein sind.',
      desc: '',
    },
    {
      name: 'Bewusstseinszustand',
      id: 'ansprechbar',
      value: '... die teilweise ansprechbar sind.',
      desc: '',
    },
    {
      name: 'Bewusstseinszustand',
      id: 'nope',
      value: '... die nicht bei Bewusstsein sind.',
      desc: '',
    },
    {
      name: 'Bewusstseinszustand',
      id: 'egal',
      value: 'Das ist mir egal.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="yellow"
              index={6}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-7`}
          prev={`${AREA_CARE_PATH}/spielen/frage-5`}
          index={6}
        />

        <Icon position="3" name="speachbubbles" />
      </main>
    </>
  )
}
